import React from "react"
import PropTypes from "prop-types"

class HelloWorld extends React.Component {

	
  render () {
    return (
      <React.Fragment>
        Greeting: {this.props.meeting}
      </React.Fragment>
    );
  }
}

export default HelloWorld
