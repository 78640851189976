import React from "react"
import PropTypes from "prop-types"
import FavoriteIcon from "@material-ui/icons/Favorite"
import MessageIcon from "@material-ui/icons/Message"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import ShareIcon from "@material-ui/icons/Share"
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import BlockIcon from '@material-ui/icons/Block';

const iframe_container = {
    left: 0,
              width: 323,
              height: 554,
              position: "relative"
            }

const iframe ={top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    border: 0}

class Video extends React.Component {
    constructor(props) {
        super(props);

        console.log("VIDEO!");
        console.log(props);

        this.state = {
            playing: false,
            liked: false,
            addVideo: false,
            addProfile: false,
            videoFolders: this.props.video_folders,
            profileFolders: this.props.account_folders,
            selectedVideoFolderId: "",
            selectedAccountFolderId: "",
            visible: true,
            clicked: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeA = this.handleChangeA.bind(this);
        this.handleSubmitA = this.handleSubmitA.bind(this);
    }

    onVideoPress = () => {
        // You can use the play method as normal on your video ref
        if (this.state.playing) {
            this.refs.vidRef.pause();
            this.setState({ playing: false });
        } else {
            this.refs.vidRef.play();
            this.setState({ playing: true }); 
        }
      };

    addVideo = () => {
        console.log("addVideo click");

        this.setState({ addVideo: true });

        fetch("/api/favorites/video_folders")
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result)
              this.setState({
                videoFolders: result,
                selectedVideoFolderId: result[0].id
              });
            },
            (error) => {
              console.log("error");
            }
          )
    }

    addVideoFav = () => {

        const token = document.querySelector('[name=csrf-token]').content
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token,
            },
            body: JSON.stringify({ video_id: this.props.video.id })
        };

        fetch('/api/favorites/add', requestOptions)
        .then(response => response.json())
        .then(data => 
            console.log(data)
        );
    }

    import = () => {
        let importUrl = $('#remoteUrl').val();
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ video_id: this.props.video })
        };
        fetch(importUrl, requestOptions)
        .then(response => response.json())
        .then(data => 
            alert('Успешно импортировано!')
        );
    }

    banProfile = () => {


        let token = document.querySelector('[name=csrf-token]').content
        let requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token
            },
            body: JSON.stringify({ video_id: this.props.video.id })
        };

        fetch('/api/ban_profile', requestOptions)
        .then(response => response.json())
        .then(data => 
            this.setState({ visible: false })
        );
    }

    addProfile = () => {
        alert("Not implemented yet!");
    }

    // Submit add video to favorites
    handleSubmit = (event) => {
        event.preventDefault();
        const token = document.querySelector('[name=csrf-token]').content
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token,
            },
            body: JSON.stringify({ video_id: this.props.video.id, folder_id: this.state.selectedVideoFolderId, fav_type: "video" })
        };

        fetch('/api/favorites/add', requestOptions)
        .then(response => response.json())
        .then(data => 
            console.log(data)
        );
    }

    videoClick = (event) => {
        //alert('clicked');

        // invert state
        this.setState({ clicked: true });
    }

    handleChange = (event) => {
        this.setState({selectedVideoFolderId: event.target.value}); 
    }

    handleChangeA = (event) => {
        this.setState({selectedAccountFolderId: event.target.value}); 
    }

    handleSubmitA = (event) => {
        event.preventDefault();
        const token = document.querySelector('[name=csrf-token]').content
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token,
            },
            body: JSON.stringify({ video_id: this.props.video.id, folder_id: this.state.selectedAccountFolderId, fav_type: "acc" })
        };

        fetch('/api/favorites/add', requestOptions)
        .then(response => response.json())
        .then(data => 
            console.log(data)
        );
    }

    openTiktok = () => {
      let url = "https://www.tiktok.com/@"+this.props.video.profileName+"/videos/"+this.props.video.tiktok_id;
      window.open(url , "_blank");
    }



    render () {
        let klassName = "video__player";
        let visible = this.state.visible;

        if (this.props.video.profile_link) {
            klassName = "video__player profile-link"
        }

        let addVideoButton, addProfileButton;

        console.log('alarm!1');
        console.log(this.props.video_folders);

        const folderOptions = this.props.video_folders.map((folder) =>
          <option key={folder.id} value={folder.id}>{folder.name}</option>
        );

        const folderOptions2 = this.props.account_folders.map((folder) =>
          <option key={folder.id} value={folder.id}>{folder.name}</option>
        );

        addVideoButton = (<div className="media-button fav-block" >
                            <form onSubmit={this.handleSubmit}>
                                <select name="folders"
                                        id="folders"
                                        value={this.state.selectedVideoFolderId}
                                        onChange={this.handleChange}
                                        >
                                    <option></option>
                                    {folderOptions}
                                </select>

                                <button type="submit" className="bx--btn bx--btn--primary bx-like">
                                    <FavoriteBorderIcon />
                                </button>
                            </form>
                        </div>
                        )

        addProfileButton = (<div className="media-button fav-block" >
                                <form onSubmit={this.handleSubmitA}>
                                    <select name="folders"
                                            id="folders"
                                            value={this.state.selectedAccountFolderId}
                                            onChange={this.handleChangeA}
                                            >
                                        <option></option>
                                        {folderOptions2}
                                    </select>

                                    <button type="submit" className="bx--btn bx--btn--primary bx-like" >
                                        <FavoriteBorderIcon />
                                    </button>
                                </form>
                        </div>
                        )

        let cover = this.props.video.cover ? this.props.video.cover.url : this.props.video.coverUrl; 
        let src = this.props.video.video ? this.props.video.video.url : this.props.video.videoUrl;
        let url = this.props.video.profile_link_url == "" ? "" : this.props.video.profile_link_url;

        let background = this.props.video.coverUrl;

        let clicked = this.state.clicked;

        let video;

        if (clicked == true) {
            video = (
                <div className="video" style={{  
                          backgroundImage: "url(" + background + ")",
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                        }}

                    onClick={this.videoClick}
                    >

                    <div
                        className={"video-preview "+klassName}
                    >
                        
                    </div>
                    {/*
                    <video ref="vidRef"
                           onClick={this.onVideoPress}
                           className={klassName}
                           controls={true}
                           src={src}
                           cover={cover}>
                    </video>
                    */}

                </div>
                )
        } else {
            video = (
                <div className="video">
                    
                    <div className={iframe_container}>
                <iframe
                  src={"https://www.tiktok.com/embed/"+this.props.video.tiktok_id+"?lang=en&is_copy_url=0&is_from_webapp=v1&sender_device=pc&sender_web_id=6891271001062401286&is_from_webapp=v1&is_copy_url=0"}
                  className="video-iframe"
                  allowfullscreen
                  scrolling="no"
                  allow="encrypted-media;"
                ></iframe>
              </div>


                </div>
            )
        }

        if (visible) {
            return (
            <div class="video-item">
                
                {video}

                <div className="video-meta">
                    <div className="flex-c flex-column">
                        <div className="video-meta-stats">
                            <div className="stats-block">
                                <ShareIcon fontSize="small" />
                                <div>{this.props.video.shareCount}</div>
                            </div> 
                            <div className="stats-block">
                                <MessageIcon fontSize="small" />
                                <div>{this.props.video.commentCount}</div>
                            </div>
                            <div className="stats-block">
                                <PlayArrowIcon fontSize="small" />
                                <div>{this.props.video.playCount}</div>
                            </div>
                        </div>
                        <div className="video-meta-url">
                            <a href={url} target="_blank">{url}</a>
                        </div>
                        <div className="video-meta-desc">
                            {this.props.video.description}
                        </div>
                    </div>
                    <div className="media-buttons">

                            {addVideoButton}

                            {addProfileButton}

                            <button className="bx--btn bx--btn--danger bx--btn--icon-only bx--tooltip__trigger bx--tooltip--a11y bx--tooltip--top bx--tooltip--align-center bx-like mr-5"
                            onClick={this.banProfile}>
                                <span class="bx--assistive-text">Забанить аккаунт</span>
                                <BlockIcon />
                            </button>

                            <button className="bx--btn bx--btn--tertiary bx--btn--icon-only bx--tooltip__trigger bx--tooltip--a11y bx--tooltip--top bx--tooltip--align-center"
                               onClick={this.openTiktok} href={"https://www.tiktok.com/@"+this.props.video.profileName+"/"+this.props.video.tiktok_id}>
                                <span class="bx--assistive-text">Перейти в ТикТок</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="-0.32296740998066475 -3.1283528999801873 42.68446958125966 42.128352899980186"><g fill="none">
                                    <path d="M14 15.599v-1.486A13.1 13.1 0 0 0 12.337 14C5.535 14 0 19.18 0 25.547 0 29.452 2.086 32.91 5.267 35c-2.13-2.132-3.315-4.942-3.313-7.861 0-6.276 5.377-11.394 12.046-11.54" fill="#00f2ea"/>
                                    <path d="M14.327 32c2.876 0 5.221-2.273 5.328-5.107l.01-25.292h4.65A8.72 8.72 0 0 1 24.164 0h-6.35l-.011 25.293c-.106 2.832-2.453 5.105-5.328 5.105a5.329 5.329 0 0 1-2.476-.61A5.34 5.34 0 0 0 14.327 32m18.672-21.814V8.78a8.818 8.818 0 0 1-4.81-1.421A8.85 8.85 0 0 0 33 10.186" fill="#00f2ea"/><path d="M28 7.718A8.63 8.63 0 0 1 25.832 2h-1.697A8.735 8.735 0 0 0 28 7.718M12.325 20.065c-2.94.004-5.322 2.361-5.325 5.27A5.267 5.267 0 0 0 9.854 30a5.2 5.2 0 0 1-1.008-3.073c.003-2.91 2.385-5.268 5.325-5.271.55 0 1.075.09 1.572.244v-6.4a11.72 11.72 0 0 0-1.572-.114c-.092 0-.183.006-.274.007v4.916a5.286 5.286 0 0 0-1.572-.244" fill="#ff004f"/><path d="M32.153 11v4.884a15.15 15.15 0 0 1-8.813-2.811V25.84c0 6.377-5.23 11.565-11.658 11.565-2.485 0-4.789-.778-6.682-2.097A11.67 11.67 0 0 0 13.528 39c6.429 0 11.659-5.188 11.659-11.564V14.668A15.15 15.15 0 0 0 34 17.478v-6.283A8.87 8.87 0 0 1 32.153 11" fill="#ff004f"/><path d="M23.979 25.42V12.632A15.741 15.741 0 0 0 33 15.448v-4.89a9.083 9.083 0 0 1-4.912-2.82C26.016 6.431 24.586 4.358 24.132 2h-4.747l-.01 25.215c-.11 2.824-2.505 5.09-5.44 5.09-1.754-.002-3.398-.822-4.42-2.204-1.794-.913-2.919-2.716-2.92-4.682.003-2.92 2.44-5.285 5.45-5.289.56 0 1.098.09 1.608.245v-4.933C7.202 15.589 2 20.722 2 27.016c0 3.045 1.219 5.816 3.205 7.885A12.115 12.115 0 0 0 12.045 37c6.58 0 11.934-5.195 11.934-11.58" fill="#fff"/></g>
                                </svg>
                            </button>

                            <button className="bx--btn bx--btn--success bx--btn--icon-only bx--tooltip__trigger bx--tooltip--a11y bx--tooltip--top bx--tooltip--align-center bx-like mr-5"
                            onClick={this.import}>
                                import
                            </button>
                            
                    </div>
                </div>
            </div>
        )
        } else {
            return (
                <div>
                </div>
            )
        }
    }
}

export default Video