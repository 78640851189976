import React from "react"
import PropTypes from "prop-types"
import Video from "./Video.jsx"
import FavoriteIcon from "@material-ui/icons/Favorite"
import MessageIcon from "@material-ui/icons/Message"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import ShareIcon from "@material-ui/icons/Share"
import ViewStreamIcon from '@material-ui/icons/ViewStream'
import AppsIcon from '@material-ui/icons/Apps';
import {List} from 'react-virtualized';

class Feed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: this.props.isLoading,
      videos: this.props.videos,
      view: "grid",
      status: {}
    }
  }

  componentDidUpdate() {

  }

  componentDidMount() {
    fetch("/api/feed/"+this.props.feed_id+"/status")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result.isLoading);
          this.setState({
            isLoading: result.isLoading,
          });
        },
        (error) => {
          console.log("error");
        }
      )
    if (this.state.isLoading) {
      //this.fetchStatus()
      this.interval = setInterval(() => {
        this.fetchStatus()
      }, 1000)
    }
  }

  fetchStatus = () => {
    fetch("/api/feed/"+this.props.feed_id+"/status")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result.isLoading);
          if (result.isLoading == true) {
            this.setState({
              status: {
                total: result.status.total, 
                found_total: result.status.found_total,
                found: result.status.found
              }
            })
          }          

          if (result.isLoading == false) {
            clearInterval(this.interval);
            this.setState({
              isLoading: result.isLoading,
              videos: []
            });
            this.fetchVideos();
          }
        },
        (error) => {
          console.log("error");
        }
      )
  }

  fetchVideos = () => {
    fetch("/api/feed/"+this.props.feed_id+"/videos")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            videos: result,
            isLoading: false
          });
        },
        (error) => {
          console.log("error");
        }
      )
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  scrapeFeed = () => {

    const token = document.querySelector('[name=csrf-token]').content


    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': token
        }
    };
    fetch('/feed/'+this.props.feed_id+'/scrape', requestOptions)
        .then(response => response.json())
        .then(data => 
          this.setState({
            isLoading: true,
            videos: []
          })
        );

    this.interval = setInterval(() => {
        this.fetchStatus()
      }, 1000)
  }

  changeView = () => {
    let currentView = this.state.view;
    let nextView = currentView == "list" ? "grid" : "list";

    this.setState({
      view: nextView
    })
  }

  render () {
    let isLoading = this.state.isLoading;

  	const listVideos = this.state.videos.map((video) =>
      <Video key={video.id}
             video={video}
             video_folders={this.props.video_folders}
             account_folders={this.props.account_folders} />
    );

    let videoClass = this.state.view == "list" ? "list" : "grid";

    let viewBtnClass,viewBtnClass2;

    if (this.state.view == "list"){
      viewBtnClass = "bx--btn--primary"
      viewBtnClass2 = "bx--btn--tertiary"
    } else {
      viewBtnClass = "bx--btn--tertiary"
      viewBtnClass2 = "bx--btn--primary"
    }

    if (isLoading){
      return (
        <div className="feed">
          <h2>Парсинг в процессе...
          </h2>
          <h4>
            Спарсили всего: {this.state.status.found_total}.<br/>
            Найдено: {this.state.status.found} из {this.state.status.total}.
          </h4>
        </div>
      )
    } else if (this.props.feed_id == null) {
      return (
        <div className="feed">
          <div className={"video-"+videoClass}>
            {listVideos}
          </div>
        </div>
      )
    } else {
      return (
        <div className="feed">

          <div>
            <h4>
              Лента {this.props.name} | Спарсено: {this.state.videos.length}
            </h4>

            <div className="buttons-panel">

              <div className="buttons-panel-left">
                <a className="bx--btn bx-like  bx--btn--tertiary padding-right"
                   href={"/feed/"+this.props.feed_id+"/edit"}>Настройки ленты</a>

                <a className="bx--btn bx-like  bx--btn--secondary padding-right"
                   href={"/feed/"+this.props.feed_id+"/links"}>Ссылки</a>

              </div>

              {/*
              <div className="buttons-panel-right">
                <a className={"bx--btn bx-like  "+viewBtnClass} onClick={this.changeView}>
                  <ViewStreamIcon />
                </a>
                <a className={"bx--btn bx-like  "+viewBtnClass2} onClick={this.changeView}>
                  <AppsIcon />
                </a>
              </div>
              */}

            </div>

          </div>

          <div className={"video-"+videoClass}>
            {listVideos}
          </div>


        </div>
      )
    }
    // По идее кнопка запроса на поиск, чтобы изменять state
  
  }
}

export default Feed
