import React from "react";
import PropTypes from "prop-types";
import loremIpsum from 'lorem-ipsum';
import {List} from 'react-virtualized';
import faker from 'faker';

function createRecord(count) {
  let records = [];

  for (let i = 0; i < count; i++) {
    records.push({
      username: faker.internet.userName(),
      email: faker.internet.email()
    });
  }
  return records;
}

const records = createRecord(100000);
const height = 700;
const rowHeight = 40;
const width = 800;


class Listing extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			data: records
		}
	}

	rowRenderer = ({ index, isScrolling, key, style }) => {
	    return (
	      <div key={key} style={style}>
	        <div>{this.state.data[index].username}</div>
	        <div>{this.state.data[index].email}</div>
	      </div>
	    );
	  };

	render () {
		return (
			<div>
				<List
		          rowCount={this.state.data.length}
		          width={width}
		          height={height}
		          rowHeight={rowHeight}
		          rowRenderer={this.rowRenderer}
		          overscanRowCount={3}
		        />		
			</div>
		)
	}
}

export default Listing